import { createContext, useRef } from 'react';
import useBoardConfigurations from '../hooks/useBoardConfigurations';
import { useBoardStates } from '../hooks/useBoardStates';
import Board from './Board';
import styles from './Boards.module.scss';
import useLayoutScale from '../hooks/useLayoutScale';
import EnvironmentLabel from './EnvironmentLabel';
import { StopBoardHeader } from '../models/stopBoardHeader';
import { useTitle } from '../hooks/useTitle';
import useTheme from '../hooks/useTheme';
import { Theme } from '../models/theme';

export interface BoardContextState {
  isFirst: boolean;
  isLast: boolean;
}

export const BoardContext = createContext<BoardContextState>({
  isFirst: false,
  isLast: false,
});

export const ThemeContext = createContext<Theme>('dark');

export default function Boards() {
  const theme = useTheme();

  const { boardConfigurations, error } = useBoardConfigurations();
  const { hasConnectionError, boardStates } =
    useBoardStates(boardConfigurations);

  const redirecting = useRef(false);
  if (
    !redirecting.current &&
    boardConfigurations?.length === 0 &&
    error === undefined
  ) {
    alert('Måste ange en konfiguration');
    window.location.href =
      'https://www.vasttrafik.se/reseplanering/mer-om-reseplanering/avgangstavla/';
    redirecting.current = true;
  }

  useLayoutScale(boardConfigurations?.length);

  function getTitle() {
    const boardDisplayNames = boardStates
      .map((boardState) => getDisplayName(boardState.header))
      .filter((displayName) => displayName !== undefined);

    if (boardDisplayNames.length === 0) {
      return 'GDS';
    }

    return boardDisplayNames.join(' & ');
  }

  function getDisplayName(
    header: StopBoardHeader | undefined
  ): string | undefined {
    if (!header) {
      return undefined;
    }

    if (!header.platforms || header.platforms.length === 0) {
      return header.stopAreaName;
    }

    return `${header.stopAreaName} ${header.platforms.join(', ')}`;
  }
  const title = getTitle();
  useTitle(title);

  return (
    <ThemeContext.Provider value={theme}>
      <div className={styles.boards}>
        {boardConfigurations &&
          boardConfigurations.map(
            (boardConfiguration, boardConfigurationIndex) => {
              const boardState = boardStates.find(
                (boardStateCandidate) =>
                  boardStateCandidate.configurationId === boardConfiguration.id
              );

              return (
                <BoardContext.Provider
                  key={boardConfiguration.id}
                  value={{
                    isFirst: boardConfigurationIndex === 0,
                    isLast:
                      boardConfigurationIndex ===
                      boardConfigurations.length - 1,
                  }}
                >
                  <Board
                    hasConnectionError={hasConnectionError}
                    boardConfiguration={boardConfiguration}
                    boardState={boardState}
                  />
                </BoardContext.Provider>
              );
            }
          )}
        {error && <div>{error}</div>}
        <EnvironmentLabel />
      </div>
    </ThemeContext.Provider>
  );
}
