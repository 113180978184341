import { DepartureOrArrival } from '@shared/models/departureOrArrival';
import { dateToClockFormat } from '@shared/utils/time';

export function getHasChangedPlannedClockTime(
  departure: DepartureOrArrival
): boolean {
  const plannedTimeClock = dateToClockFormat(
    departure.plannedTime ?? new Date()
  );
  const estimatedOtherwisePlannedTimeClock = dateToClockFormat(
    departure.estimatedOtherwisePlannedTime
  );

  return (
    departure.isCancelled ||
    plannedTimeClock !== estimatedOtherwisePlannedTimeClock
  );
}
