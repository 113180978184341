import { DepartureRow } from 'src/modules/departures/models/departureRow';
import { ArrivalRow } from 'src/modules/arrivals/models/arrivalRow';
import classNames from 'classnames';
import DepartureOrArrivalTableLineCell from './DepartureOrArrivalTableLineCell';
import DepartureOrArrivalTableTransportModeCell from './DepartureOrArrivalTableTransportModeCell';
import DepartureOrArrivalTableDirectionOrOriginCell from './DepartureOrArrivalTableDirectionOrOriginCell';
import DepartureOrArrivalTableDepartureCell from './DepartureOrArrivalTableDepartureOrArrivalCell';
import DepartureOrArrivalTablePlatformCell from './DepartureOrArrivalTablePlatformCell';
import styles from './DepartureOrArrivalTable.module.scss';
import {
  createRef,
  CSSProperties,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import usePagination from '@shared/hooks/usePagination';
import useElementSize from '@shared/hooks/useElementSize';
import {
  PAGINATION_TIME_IN_SECONDS,
  SECONDS_BETWEEN_DEPARTURES_OR_ARRIVALS_PAGE_CHANGE,
} from '@shared/constants';
import PageIndicator from '@shared/components/PageIndicator';
import { getRemPropertyPixelSize } from '@shared/utils/rem';
import { compareRows } from '@shared/utils/departureOrArrivalRowComparer';
import { DepartureOrArrivalSorting } from '@shared/models/departureOrArrivalSorting';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  prepareExitAnimation,
  preparePageExitAnimations,
} from '@shared/utils/exitAnimation';
import { DepartureOrArrivalTimeFormat } from '@shared/models/departureOrArrivalTimeFormat';
import DepartureOrArrivalTablePlannedTimeCell from './DepartureOrArrivalTablePlannedTimeCell';
import { getHasChangedPlannedClockTime } from '@shared/utils/departureOrArrival';

interface DepartureOrArrivalTableProps {
  departureRows?: DepartureRow[];
  arrivalRows?: ArrivalRow[];
  sorting: DepartureOrArrivalSorting;
  timeFormat: DepartureOrArrivalTimeFormat;
  paging: boolean;
  showPlatform: boolean;
}

export default function DepartureOrArrivalTable({
  departureRows,
  arrivalRows,
  sorting,
  timeFormat,
  paging,
  showPlatform,
}: DepartureOrArrivalTableProps) {
  const departuresOrArrivalsType: 'departures' | 'arrivals' = useMemo(() => {
    if (departureRows !== undefined) {
      return 'departures';
    }

    return 'arrivals';
  }, [departureRows]);
  const departureOrArrivalRows: (DepartureRow | ArrivalRow)[] = useMemo(() => {
    if (departureRows !== undefined) {
      return departureRows;
    }

    if (arrivalRows !== undefined) {
      return arrivalRows;
    }

    return [];
  }, [departureRows, arrivalRows]);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerSize = useElementSize(containerRef);
  const tableHeadRef = useRef<HTMLTableSectionElement>(null);
  const tableHeadSize = useElementSize(tableHeadRef);
  const pageIndicatorRef = useRef<HTMLDivElement>(null);
  const pageIndicatorSize = useElementSize(pageIndicatorRef);
  const availableHeight =
    containerSize.height && tableHeadSize.height
      ? containerSize.height -
        tableHeadSize.height -
        (pageIndicatorSize.height ?? 0)
      : undefined;
  const allElementRefs = useRef<Map<string, HTMLTableRowElement>>(new Map());
  const currentPageElementRefs = useRef<
    Map<string, RefObject<HTMLTableRowElement>>
  >(new Map());
  function getOrCreateCurrentRowElementRef(paginationId: string) {
    const existingRef = currentPageElementRefs.current.get(paginationId);
    if (existingRef) {
      return existingRef;
    } else {
      const newRef = createRef<HTMLTableRowElement>();
      currentPageElementRefs.current.set(paginationId, newRef);
      return newRef;
    }
  }
  function removeCurrentRowElementRef(paginationId: string) {
    currentPageElementRefs.current.delete(paginationId);
  }

  const sortedDepartureOrArrivalRows = useMemo(
    () =>
      [...departureOrArrivalRows].sort((first, second) =>
        compareRows(first, second, sorting)
      ),
    [departureOrArrivalRows, sorting]
  );

  const pageChangeSortingFunction = useCallback(() => {
    if (sorting === 'time') {
      return (
        first: DepartureRow | ArrivalRow,
        second: DepartureRow | ArrivalRow
      ) => compareRows(first, second, sorting);
    }

    return undefined;
  }, [sorting]);

  const { items, itemsForCurrentPage, currentPageIndex, pages, nextPage } =
    usePagination(
      availableHeight,
      sortedDepartureOrArrivalRows,
      allElementRefs.current,
      'id',
      {
        directlyUpdateItemsWithMostCurrentItems: true,
        singlePage: !paging,
        pageChangeSortingFunction: pageChangeSortingFunction(),
      }
    );

  const renderDepartureOrArrivalColumn =
    timeFormat === 'countdown' ||
    itemsForCurrentPage.some(([departureOrArrivalRow]) =>
      getHasChangedPlannedClockTime(departureOrArrivalRow.next)
    );
  const renderPlannedTimeColumn = timeFormat === 'time';
  const hasThereafter = departureOrArrivalRows.some(
    (departureOrArrivalRow) => departureOrArrivalRow.thereafter !== undefined
  );
  const renderThereafterColumn = hasThereafter;
  const hasDeparturesOrArrivalsWithPlatform = departureOrArrivalRows.some(
    (departureOrArrivalRow) => departureOrArrivalRow.platform !== undefined
  );
  const renderPlatformColumn =
    showPlatform && hasDeparturesOrArrivalsWithPlatform;

  const directionHeaderRef = useRef<HTMLTableCellElement>(null);
  const directionHeaderSize = useElementSize(directionHeaderRef);

  const plannedTimeHeaderRef = useRef<HTMLTableCellElement>(null);
  const plannedTimeHeaderSize = useElementSize(plannedTimeHeaderRef, {
    renderRef: renderPlannedTimeColumn,
  });

  const nextHeaderRef = useRef<HTMLTableCellElement>(null);
  const nextHeaderSize = useElementSize(nextHeaderRef);

  const thereafterHeaderRef = useRef<HTMLTableCellElement>(null);
  const thereafterHeaderSize = useElementSize(thereafterHeaderRef, {
    renderRef: renderThereafterColumn,
  });

  const platformHeaderRef = useRef<HTMLTableCellElement>(null);
  const platformHeaderSize = useElementSize(platformHeaderRef, {
    renderRef: renderPlatformColumn,
  });

  const hasNextWithTrafficSituation = items.some(
    (departureOrArrivalRow) =>
      departureOrArrivalRow.next.trafficSituationSeverity !== undefined
  );
  const hasThereafterWithTrafficSituation = items.some(
    (departureOrArrivalRow) =>
      departureOrArrivalRow.thereafter?.trafficSituationSeverity !== undefined
  );
  function getExpandDirectionWidth(): boolean {
    if (!containerRef.current || containerSize.width === undefined) {
      return false;
    }
    const directionTextWidth =
      getRemPropertyPixelSize(containerRef.current, '--heading-2-font-size') *
      12;
    const directionWidthRatio = directionTextWidth / containerSize.width;
    return directionWidthRatio > 0.5;
  }
  const expandDirectionWidth = getExpandDirectionWidth();

  const prepareAnimationsAndGoToNextPage = useCallback(() => {
    preparePageExitAnimations(currentPageElementRefs);

    nextPage();
  }, [nextPage]);

  const dividerWidth =
    tableHeadSize.width !== undefined ? `${tableHeadSize.width}px` : undefined;

  return (
    <div
      ref={containerRef}
      className={styles['container']}
      style={{ '--divider-width': dividerWidth } as CSSProperties}
    >
      <table className={styles['all-departures-or-arrivals']}>
        <thead ref={tableHeadRef}>
          <tr>
            <th scope="col">
              <span className="sr-only">Linje</span>
            </th>
            <th scope="col">
              <span className="sr-only">Fordonstyp</span>
            </th>
            <th
              ref={directionHeaderRef}
              scope="col"
              className={styles['direction-or-origin']}
              style={{ width: expandDirectionWidth ? '100%' : undefined }}
            >
              {departuresOrArrivalsType === 'departures' && (
                <span className="sr-only">Mot</span>
              )}
              {departuresOrArrivalsType === 'arrivals' && 'Från'}
            </th>
            {renderDepartureOrArrivalColumn && (
              <th ref={nextHeaderRef} scope="col" className={styles.next}>
                {timeFormat === 'countdown' && (
                  <>
                    {departuresOrArrivalsType === 'arrivals'
                      ? 'Ankommer om'
                      : 'Nästa'}
                    <span aria-hidden="true"> (min)</span>
                  </>
                )}
                {timeFormat === 'time' && <>Ny tid</>}
              </th>
            )}
            {renderPlannedTimeColumn && (
              <th
                ref={plannedTimeHeaderRef}
                scope="col"
                className={styles['next-planned']}
              >
                Tid
              </th>
            )}
            {renderThereafterColumn && (
              <th
                ref={thereafterHeaderRef}
                scope="col"
                className={styles['thereafter']}
              >
                Därefter
              </th>
            )}
            {renderPlatformColumn && (
              <th
                ref={platformHeaderRef}
                scope="col"
                className={styles.platform}
              >
                Läge
              </th>
            )}
          </tr>
        </thead>
        <tbody aria-live="polite">
          {departureOrArrivalRows.map((departureOrArrivalRow) => (
            <tr
              key={departureOrArrivalRow.id}
              ref={(rowElement) => {
                if (rowElement) {
                  allElementRefs.current.set(
                    departureOrArrivalRow.id,
                    rowElement
                  );
                } else {
                  allElementRefs.current.delete(departureOrArrivalRow.id);
                }
              }}
            >
              <DepartureOrArrivalTableLineCell
                line={departureOrArrivalRow.line}
              />
              <DepartureOrArrivalTableTransportModeCell
                transportMode={departureOrArrivalRow.transportMode}
              />
              <DepartureOrArrivalTableDirectionOrOriginCell
                directionOrOrigin={
                  'direction' in departureOrArrivalRow
                    ? departureOrArrivalRow.direction
                    : departureOrArrivalRow.origin
                }
                tags={
                  'tags' in departureOrArrivalRow
                    ? departureOrArrivalRow.tags
                    : undefined
                }
                timeFormat={timeFormat}
              />
              {renderDepartureOrArrivalColumn && (
                <DepartureOrArrivalTableDepartureCell
                  departureOrArrival={departureOrArrivalRow.next}
                  compact={expandDirectionWidth}
                  format={timeFormat}
                  hasDepartureOrArrivalWithTrafficSituation={
                    hasNextWithTrafficSituation
                  }
                />
              )}
              {renderPlannedTimeColumn && (
                <DepartureOrArrivalTablePlannedTimeCell
                  departureOrArrival={departureOrArrivalRow.next}
                />
              )}
              {renderThereafterColumn && (
                <DepartureOrArrivalTableDepartureCell
                  departureOrArrival={departureOrArrivalRow.thereafter}
                  compact={expandDirectionWidth}
                  format={timeFormat}
                  hasDepartureOrArrivalWithTrafficSituation={
                    hasThereafterWithTrafficSituation
                  }
                />
              )}
              {renderPlatformColumn && (
                <DepartureOrArrivalTablePlatformCell
                  platform={departureOrArrivalRow.platform}
                />
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <table
        className={styles['departures-or-arrivals']}
        style={{ marginTop: `${tableHeadSize.height}px` }}
        aria-hidden="true"
      >
        <TransitionGroup component="tbody">
          {itemsForCurrentPage.map(
            ([departureOrArrivalRow, paginationId], index) => (
              <CSSTransition
                key={paginationId}
                nodeRef={getOrCreateCurrentRowElementRef(paginationId)}
                classNames="paged-item"
                timeout={PAGINATION_TIME_IN_SECONDS * 1000}
                onExit={() => {
                  if (pages <= 1) {
                    prepareExitAnimation(
                      getOrCreateCurrentRowElementRef(paginationId)
                    );
                  }
                }}
                onExited={() => {
                  removeCurrentRowElementRef(paginationId);
                }}
              >
                <tr
                  ref={getOrCreateCurrentRowElementRef(paginationId)}
                  className={classNames(
                    styles['row'],
                    index + 1 === itemsForCurrentPage.length &&
                      styles['row--last-for-page']
                  )}
                >
                  <DepartureOrArrivalTableLineCell
                    line={departureOrArrivalRow.line}
                  />
                  <DepartureOrArrivalTableTransportModeCell
                    transportMode={departureOrArrivalRow.transportMode}
                  />
                  <DepartureOrArrivalTableDirectionOrOriginCell
                    directionOrOrigin={
                      'direction' in departureOrArrivalRow
                        ? departureOrArrivalRow.direction
                        : departureOrArrivalRow.origin
                    }
                    tags={
                      'tags' in departureOrArrivalRow
                        ? departureOrArrivalRow.tags
                        : undefined
                    }
                    timeFormat={timeFormat}
                    headerWidth={directionHeaderSize.width}
                  />
                  {renderDepartureOrArrivalColumn && (
                    <DepartureOrArrivalTableDepartureCell
                      departureOrArrival={departureOrArrivalRow.next}
                      compact={expandDirectionWidth}
                      format={timeFormat}
                      hasDepartureOrArrivalWithTrafficSituation={
                        hasNextWithTrafficSituation
                      }
                      headerWidth={nextHeaderSize.width}
                    />
                  )}
                  {renderPlannedTimeColumn && (
                    <DepartureOrArrivalTablePlannedTimeCell
                      departureOrArrival={departureOrArrivalRow.next}
                      headerWidth={plannedTimeHeaderSize.width}
                    />
                  )}
                  {renderThereafterColumn && (
                    <DepartureOrArrivalTableDepartureCell
                      departureOrArrival={departureOrArrivalRow.thereafter}
                      compact={expandDirectionWidth}
                      format={timeFormat}
                      hasDepartureOrArrivalWithTrafficSituation={
                        hasThereafterWithTrafficSituation
                      }
                      headerWidth={thereafterHeaderSize.width}
                    />
                  )}
                  {renderPlatformColumn && (
                    <DepartureOrArrivalTablePlatformCell
                      platform={departureOrArrivalRow.platform}
                      headerWidth={platformHeaderSize.width}
                    />
                  )}
                </tr>
              </CSSTransition>
            )
          )}
        </TransitionGroup>
      </table>
      {paging && (
        <div
          ref={pageIndicatorRef}
          className={styles['container__page-indicator']}
        >
          {pages > 1 && (
            <PageIndicator
              currentPagePaginationTime={
                SECONDS_BETWEEN_DEPARTURES_OR_ARRIVALS_PAGE_CHANGE
              }
              currentPageIndex={currentPageIndex}
              pages={pages}
              paging={prepareAnimationsAndGoToNextPage}
            />
          )}
        </div>
      )}
    </div>
  );
}
