import { useRef } from 'react';
import { useScaledFontSize } from '@shared/hooks/useScaledFontSize';
import type { Line } from '@shared/models/line';
import styles from './Line.module.scss';

interface LineProps {
  line: Line;
}

export default function Line({ line }: LineProps) {
  const lineNameRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(lineNameRef);

  return (
    <div className={styles.line}>
      <div
        ref={lineNameRef}
        className={styles.line__name}
        style={{
          color: line.foregroundColor,
          backgroundColor: line.backgroundColor,
          borderColor: line.foregroundColor,
        }}
      >
        {line.shortName}
      </div>
    </div>
  );
}
