import { useEffect, useState } from 'react';
import { BoardConfiguration } from '../models/boardConfiguration';
import { BoardId } from '../models/boardId';
import { useServerSentEvents } from './useServerSentEvents';
import { BoardState } from '@shared/models/BoardState';
import { useMqtt } from './useMqtt';

export function useBoardStates(boards: BoardConfiguration[] | undefined) {
  const [boardStates, setBoardStates] = useState(
    new Map<BoardId, BoardState>()
  );

  useEffect(() => {
    setBoardStates(new Map<BoardId, BoardState>());
  }, [boards]);

  const { hasConnectionError } = useServerSentEvents(boards, setBoardStates);
  useMqtt(boards, setBoardStates);

  return { hasConnectionError, boardStates: [...boardStates.values()] };
}
