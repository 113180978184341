import { DepartureRow } from 'src/modules/departures/models/departureRow';
import { DepartureOrArrivalSorting } from '@shared/models/departureOrArrivalSorting';
import { ArrivalRow } from 'src/modules/arrivals/models/arrivalRow';

export function compareRows(
  first: DepartureRow | ArrivalRow,
  second: DepartureRow | ArrivalRow,
  sorting: DepartureOrArrivalSorting
): number {
  function getComparisonTime(
    row: DepartureRow | ArrivalRow
  ): number | undefined {
    if (!row.next.isCancelled) {
      return row.next.estimatedOtherwisePlannedTime.getTime();
    }

    if (row.thereafter && !row.thereafter.isCancelled) {
      return row.thereafter.estimatedOtherwisePlannedTime.getTime();
    }

    return undefined;
  }

  if (sorting === 'time') {
    const firstTime = getComparisonTime(first);
    const secondTime = getComparisonTime(second);

    if (
      firstTime !== undefined &&
      firstTime === secondTime &&
      first.next.isCancelled !== second.next.isCancelled
    ) {
      return first.next.isCancelled ? -1 : 1;
    }

    if (
      firstTime !== undefined &&
      secondTime !== undefined &&
      firstTime !== secondTime
    ) {
      return firstTime - secondTime;
    }

    if (firstTime && secondTime === undefined) {
      return -1;
    }

    if (firstTime === undefined && secondTime) {
      return 1;
    }
  }

  const firstNumeric = parseInt(first.line.shortName, 10);
  const secondNumeric = parseInt(second.line.shortName, 10);
  if (!Number.isNaN(firstNumeric) && Number.isNaN(secondNumeric)) {
    return -1;
  }

  if (Number.isNaN(firstNumeric) && !Number.isNaN(secondNumeric)) {
    return 1;
  }

  if (
    !Number.isNaN(firstNumeric) &&
    !Number.isNaN(secondNumeric) &&
    firstNumeric !== secondNumeric
  ) {
    return firstNumeric - secondNumeric;
  }

  if ('origin' in first && 'origin' in second) {
    return (
      first.line.shortName.localeCompare(second.line.shortName) ||
      first.origin.localeCompare(second.origin)
    );
  }

  if ('direction' in first && 'direction' in second) {
    return (
      first.line.shortName.localeCompare(second.line.shortName) ||
      first.direction.name.localeCompare(second.direction.name) ||
      (first.direction.via == null && second.direction.via != null ? -1 : 0) ||
      (first.direction.via != null && second.direction.via != null
        ? first.direction.via.localeCompare(second.direction.via)
        : 0)
    );
  }

  return -1;
}
