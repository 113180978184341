import { BoardConfiguration } from '../models/boardConfiguration';
import DeparturesModule from '../../modules/departures/components/DeparturesModule';
import ArrivalsModule from '../../modules/arrivals/components/ArrivalsModule';
import StopBoardHeader from './StopBoardHeader';
import TrafficSituationsModule from '../../modules/traffic-situations/components/TrafficSituationsModule';
import styles from './Board.module.scss';
import classNames from 'classnames';
import ImageIcon from './ImageIcon';
import { BoardContext } from './Boards';
import { createContext, useContext, useRef } from 'react';
import { Layout } from '../models/layout';
import {
  HORIZONTAL_BOARD_MINIMUM_ASPECT_RATIO,
  SHOW_PLATFORMS_IN_HEADER_DEFAULT,
} from '../constants';
import useElementSize from '../hooks/useElementSize';
import { TrafficSituation } from '../../modules/traffic-situations/models/trafficSituation';
import VehicleModule from '../../modules/vehicle/components/VehicleModule';
import { BoardState } from '@shared/models/BoardState';

interface BoardProps {
  hasConnectionError: boolean;
  boardConfiguration: BoardConfiguration;
  boardState?: BoardState;
}

export const LayoutContext = createContext<Layout>('horizontal');

export default function Board({
  hasConnectionError,
  boardConfiguration,
  boardState,
}: BoardProps) {
  const boardRef = useRef<HTMLDivElement>(null);
  const boardSize = useElementSize(boardRef);
  const layout =
    boardSize.width === undefined ||
    boardSize.height === undefined ||
    boardSize.width > boardSize.height * HORIZONTAL_BOARD_MINIMUM_ASPECT_RATIO
      ? 'horizontal'
      : 'vertical';

  const renderHeader =
    boardConfiguration.type === 'stop-area' ||
    boardConfiguration.type === 'stop-points';
  const renderDeparturesModule =
    (boardConfiguration.type === 'stop-area' ||
      boardConfiguration.type === 'stop-points') &&
    boardConfiguration.modules.includes('departures');
  const renderArrivalsModule =
    (boardConfiguration.type === 'stop-area' ||
      boardConfiguration.type === 'stop-points') &&
    boardConfiguration.modules.includes('arrivals');
  const renderVehicleModule = boardConfiguration.type === 'vehicle';
  const boardContext = useContext(BoardContext);
  const renderBranding =
    boardContext.isLast && boardConfiguration.type !== 'vehicle';
  const showPlatformsInHeader =
    boardConfiguration.type === 'stop-points' &&
    (boardConfiguration.showPlatformsInHeader ??
      SHOW_PLATFORMS_IN_HEADER_DEFAULT);

  function shouldRenderTrafficSituationsModule(
    trafficSituations: TrafficSituation[] | undefined
  ): trafficSituations is TrafficSituation[] {
    return (
      (boardConfiguration.type === 'vehicle' ||
        boardConfiguration.modules.includes('trafficSituations')) &&
      trafficSituations !== undefined &&
      trafficSituations.length > 0
    );
  }

  return (
    <LayoutContext.Provider value={layout}>
      <div
        ref={boardRef}
        className={classNames(
          styles.board,
          styles[`board--${layout}`],
          renderHeader && styles['board--has-header'],
          (renderDeparturesModule || renderArrivalsModule) &&
            styles['board--has-departures-or-arrivals'],
          shouldRenderTrafficSituationsModule(boardState?.trafficSituations) &&
            styles['board--has-traffic-situations'],
          renderVehicleModule && styles['board--has-vehicle'],
          renderBranding && styles['board--has-branding']
        )}
      >
        {renderHeader && (
          <div className={styles.header}>
            <StopBoardHeader
              header={boardState?.header}
              showPlatforms={showPlatformsInHeader}
              showingArrivals={renderArrivalsModule}
            />
          </div>
        )}

        {renderDeparturesModule && (
          <div className={styles['departures-or-arrivals']}>
            <DeparturesModule
              boardConfiguration={boardConfiguration}
              departureRows={boardState?.departureRows}
              departureRowsError={
                hasConnectionError || boardState?.departureOrArrivalRowsError
              }
            />
          </div>
        )}

        {renderArrivalsModule && (
          <div className={styles['departures-or-arrivals']}>
            <ArrivalsModule
              boardConfiguration={boardConfiguration}
              arrivals={boardState?.arrivalRows}
              arrivalRowsError={
                hasConnectionError || boardState?.departureOrArrivalRowsError
              }
            />
          </div>
        )}

        {renderVehicleModule && (
          <div className={styles.vehicle}>
            <VehicleModule serviceJourney={boardState?.serviceJourney} />
          </div>
        )}

        {shouldRenderTrafficSituationsModule(boardState?.trafficSituations) && (
          <div className={styles['traffic-situations']}>
            <TrafficSituationsModule
              trafficSituations={boardState.trafficSituations}
            />
          </div>
        )}

        {renderBranding && (
          <div className={styles.branding}>
            <ImageIcon
              type="branding"
              alt="Västtrafik"
              className={styles.branding__image}
            ></ImageIcon>
          </div>
        )}
      </div>
    </LayoutContext.Provider>
  );
}
