import type { ServiceJourney } from '../models/serviceJourney';
import Direction from './Direction';
import Line from './Line';
import styles from './Route.module.scss';
import Stop from './Stop';
import { Stop as StopModel } from '../models/stop';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useElementSize from '@shared/hooks/useElementSize';
import classNames from 'classnames';
import useSmoothScroll from '@shared/hooks/useSmoothScroll';

interface RouteProps {
  serviceJourney: ServiceJourney;
}

export default function Route({ serviceJourney }: RouteProps) {
  const routeRef = useRef<HTMLDivElement>(null);
  const routeSize = useElementSize(routeRef);
  const headerRef = useRef<HTMLDivElement>(null);
  const headerSize = useElementSize(headerRef);
  const availableHeight =
    routeSize.height !== undefined
      ? routeSize.height - (headerSize.height ?? 0)
      : undefined;
  const stopsMaxHeight =
    availableHeight !== undefined ? `${availableHeight}px` : undefined;

  const stopsRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const isShowingAllStops =
    availableHeight !== undefined &&
    stopsRef.current !== null &&
    availableHeight >= stopsRef.current.scrollHeight;
  const [hasCollapsedStops, setHasCollapsedStops] = useState(false);

  function getId(stop: StopModel, stopIndex: number): string {
    return `${stopIndex}-${stop.stopPointGid}`;
  }

  useEffect(() => {
    const scrollElement = stopsRef.current;
    const currentStop = serviceJourney.stops[serviceJourney.currentStopIndex];
    if (!scrollElement || !availableHeight || !currentStop) {
      return;
    }

    const currentStopId = getId(currentStop, serviceJourney.currentStopIndex);
    const scrollToElement = scrollElement.querySelector<HTMLDivElement>(
      `[data-id="${currentStopId}"]`
    );

    function tryUpdateScrollTop(): void {
      if (!scrollToElement || !availableHeight) {
        return;
      }

      const newScrollTop = Math.max(
        scrollToElement.offsetTop +
          scrollToElement.clientHeight -
          availableHeight,
        0
      );

      setScrollTop(newScrollTop);

      if (newScrollTop > 0) {
        setHasCollapsedStops(true);
      }
    }

    tryUpdateScrollTop();

    const resizeObserver = new ResizeObserver(tryUpdateScrollTop);
    resizeObserver.observe(stopsRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [availableHeight, serviceJourney]);

  const [isScrolling, setIsScrolling] = useState(false);
  const onBeginScroll = useCallback(() => {
    setIsScrolling(true);
  }, []);
  const onFinishedScroll = useCallback(() => {
    setHasCollapsedStops(
      !isShowingAllStops &&
        stopsRef.current !== null &&
        stopsRef.current.scrollTop > 0
    );
    setIsScrolling(false);
  }, [isShowingAllStops]);

  useSmoothScroll(stopsRef, scrollTop, onBeginScroll, onFinishedScroll);

  const stopGap = useMemo(() => {
    if (!stopsRef.current || !availableHeight || isShowingAllStops) {
      return undefined;
    }

    const stopElementsHeights = [...stopsRef.current.children].map(
      (element) => element.clientHeight
    );
    const currentStopElementHeight = Math.max(...stopElementsHeights);
    const otherStopElementHeight = Math.min(...stopElementsHeights);

    const otherElementsCount = Math.floor(
      (availableHeight - currentStopElementHeight) / otherStopElementHeight
    );
    const remainingHeight =
      availableHeight -
      currentStopElementHeight -
      otherStopElementHeight * otherElementsCount;

    return `${remainingHeight / otherElementsCount}px`;
  }, [availableHeight, isShowingAllStops]);

  return (
    <div ref={routeRef} className={styles.route}>
      {serviceJourney.line && serviceJourney.direction && (
        <div ref={headerRef} className={styles.header}>
          <Line line={serviceJourney.line} />
          <Direction direction={serviceJourney.direction} />
        </div>
      )}
      <div
        ref={stopsRef}
        className={classNames(
          styles.stops,
          hasCollapsedStops && styles['stops--collapsed']
        )}
        style={
          { maxHeight: stopsMaxHeight, '--stop-gap': stopGap } as CSSProperties
        }
      >
        {[...serviceJourney.stops].reverse().map((stop, reversedStopIndex) => {
          const stopIndex = serviceJourney.stops.length - reversedStopIndex - 1;
          return (
            <Stop
              key={getId(stop, stopIndex)}
              id={getId(stop, stopIndex)}
              stop={stop}
              isCurrent={stopIndex === serviceJourney.currentStopIndex}
              isPrevious={stopIndex === serviceJourney.currentStopIndex - 1}
              isPassed={stopIndex < serviceJourney.currentStopIndex}
              isLast={stopIndex === serviceJourney.stops.length - 1}
              isAtStop={serviceJourney.isAtStop}
              hasCollapsedStops={hasCollapsedStops}
              isScrolling={isScrolling}
            />
          );
        })}
      </div>
    </div>
  );
}
