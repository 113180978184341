import {
  BoardConfiguration,
  IncompleteStopBoardConfiguration,
} from '@shared/models/boardConfiguration';
import StopBoardConfigurator from './StopBoardConfigurator';
import styles from './BoardConfigurator.module.scss';

export interface BoardConfiguratorProps {
  configuratorId: number;
  configuration?: IncompleteStopBoardConfiguration | BoardConfiguration;
  onChanged: (
    configuratorId: number,
    boardConfiguration: BoardConfiguration
  ) => void;
  onDeleted: (configuratorId: number) => void;
}

export default function BoardConfigurator({
  configuratorId,
  configuration,
  onChanged,
  onDeleted,
}: BoardConfiguratorProps) {
  return (
    <fieldset className={styles['board-configurator']}>
      {configuration?.type !== 'vehicle' && (
        <StopBoardConfigurator
          configuratorId={configuratorId}
          configuration={configuration}
          onChanged={onChanged}
        ></StopBoardConfigurator>
      )}

      <button
        type="button"
        onClick={() => {
          if (window.confirm('Är du säker på att du vill ta bort vyn?')) {
            onDeleted(configuratorId);
          }
        }}
      >
        Ta bort vy
      </button>
    </fieldset>
  );
}
