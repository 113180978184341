import classNames from 'classnames';
import type { StopBoardHeader } from '../models/stopBoardHeader';
import styles from './StopBoardHeader.module.scss';
import Clock from './Clock';
import { useContext, useMemo, useRef } from 'react';
import { BoardContext } from './Boards';
import { useScaledFontSize } from '../hooks/useScaledFontSize';
import useElementSize from '../hooks/useElementSize';
import useTextWidth from '../hooks/useTextWidth';

interface StopBoardHeaderProps {
  header?: StopBoardHeader;
  showPlatforms: boolean;
  showingArrivals: boolean;
}

export default function StopBoardHeader({
  header,
  showPlatforms,
  showingArrivals,
}: StopBoardHeaderProps) {
  const stopAreaNameRef = useRef<HTMLSpanElement>(null);
  const singleLineStopAreaName =
    header?.platforms !== undefined && header.platforms.length > 1;
  useScaledFontSize(stopAreaNameRef, {
    singleLine: singleLineStopAreaName,
  });
  const hasSinglePlatform = header?.platforms?.length === 1;
  const platformsTextRef = useRef<HTMLSpanElement>(null);
  useScaledFontSize(platformsTextRef, { enabled: hasSinglePlatform });
  const platformsText = header?.platforms?.join(', ');
  const boardContext = useContext(BoardContext);

  const stopAreaRef = useRef<HTMLHeadingElement>(null);
  const stopAreaSize = useElementSize(stopAreaRef);
  const stopAreaPlatformsRef = useRef<HTMLSpanElement>(null);
  const hasPlatforms = showPlatforms && platformsText !== undefined;
  const stopAreaPlatformsSize = useElementSize(stopAreaPlatformsRef, {
    renderRef: hasPlatforms,
  });
  const stopAreaNameTextWidth = useTextWidth(
    header?.stopAreaName ?? '',
    'heading-1'
  );
  const stopAreaNameExceedsSize =
    header !== undefined &&
    stopAreaPlatformsSize.width !== undefined &&
    stopAreaSize.width !== undefined &&
    stopAreaNameTextWidth + stopAreaPlatformsSize.width >= stopAreaSize.width;

  const stopAreaName = useMemo(() => {
    if (!header?.stopAreaName) {
      return '';
    }

    let stopAreaName = header.stopAreaName;
    if (showingArrivals) {
      stopAreaName += ' (ankomster)';
    }

    return stopAreaName;
  }, [header?.stopAreaName, showingArrivals]);
  return (
    <header className={styles['stop-board-header']}>
      <h1
        ref={stopAreaRef}
        className={classNames(
          styles['stop-area'],
          stopAreaNameExceedsSize && styles['stop-area--multi-line']
        )}
      >
        <span ref={stopAreaNameRef} className={styles['stop-area__name']}>
          {stopAreaName}
        </span>
        {hasPlatforms && (
          <span
            ref={stopAreaPlatformsRef}
            className={styles['stop-area__platforms']}
          >
            <span
              className={classNames(
                styles.platforms,
                hasSinglePlatform && styles['platforms--single']
              )}
            >
              <span ref={platformsTextRef} className={styles.platforms__text}>
                {platformsText}
              </span>
            </span>
          </span>
        )}
      </h1>
      <div
        className={classNames(
          styles.clock,
          boardContext.isFirst && styles['clock--first-board'],
          boardContext.isLast && styles['clock--last-board']
        )}
      >
        <Clock />
      </div>
    </header>
  );
}
