import { useRef } from 'react';
import { Direction } from '@shared/models/direction';
import styles from './DepartureOrArrivalTableDirectionCell.module.scss';
import { useScaledFontSize } from '@shared/hooks/useScaledFontSize';
import ImageIcon from '@shared/components/ImageIcon';
import { DepartureOrArrivalTimeFormat } from '@shared/models/departureOrArrivalTimeFormat';
import useElementSize from '@shared/hooks/useElementSize';

interface DepartureOrArrivalTableDirectionCellProps {
  directionOrOrigin: Direction | string;
  tags?: string[];
  timeFormat: DepartureOrArrivalTimeFormat;
  headerWidth?: number;
}

export default function DepartureOrArrivalTableDirectionOrOriginCell({
  directionOrOrigin,
  tags,
  timeFormat,
  headerWidth,
}: DepartureOrArrivalTableDirectionCellProps) {
  const wheelchairAccessibleRef = useRef<HTMLImageElement>(null);
  const wheelCharAccessibleSize = useElementSize(wheelchairAccessibleRef, {
    includeMargin: true,
  });

  const directionNameRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(directionNameRef, {
    reservedSpace: wheelCharAccessibleSize.width,
  });

  const directionViaRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(directionViaRef);
  return (
    <td className={styles.direction} style={{ minWidth: headerWidth }}>
      <div ref={directionNameRef} className={styles['direction-name']}>
        <span className={styles['direction-name__text']}>
          {typeof directionOrOrigin === 'string'
            ? directionOrOrigin
            : directionOrOrigin.name}
        </span>
        {timeFormat === 'time' && (
          <ImageIcon
            ref={wheelchairAccessibleRef}
            type="wheelchair-accessible"
            alt="Resan är anpassad för rullstol"
            className={styles['direction-name__wheelchair-accessible']}
          ></ImageIcon>
        )}
      </div>

      {typeof directionOrOrigin !== 'string' && directionOrOrigin.via && (
        <div ref={directionViaRef} className={styles['direction-via']}>
          via {directionOrOrigin.via}
        </div>
      )}
      {tags?.map((tag, tagIndex) => (
        <span key={tag} className={styles['direction-tag']}>
          {tag}
          {tagIndex !== tags.length - 1 && <span className="sr-only">, </span>}
        </span>
      ))}
    </td>
  );
}
