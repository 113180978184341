import type { ServiceJourney } from '../models/serviceJourney';
import Route from './Route';

interface VehicleModuleProps {
  serviceJourney: ServiceJourney | undefined;
}

export default function VehicleModule({ serviceJourney }: VehicleModuleProps) {
  return <>{serviceJourney && <Route serviceJourney={serviceJourney} />}</>;
}
