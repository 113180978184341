import classNames from 'classnames';
import { Stop as StopModel } from '../models/stop';
import styles from './Stop.module.scss';
import { dateToClockFormat } from '@shared/utils/time';
import { useRef } from 'react';
import { useScaledFontSize } from '@shared/hooks/useScaledFontSize';

export interface StopParams {
  id: string;
  stop: StopModel;
  isCurrent: boolean;
  isPrevious: boolean;
  isPassed: boolean;
  isLast: boolean;
  isAtStop: boolean;
  hasCollapsedStops: boolean;
  isScrolling: boolean;
}

export default function Stop({
  id,
  stop,
  isCurrent,
  isPrevious,
  isPassed,
  isLast,
  isAtStop,
  hasCollapsedStops,
  isScrolling,
}: StopParams) {
  const stopNameRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(stopNameRef, {
    singleLine: true,
  });

  function isTimeChanged(stop: StopModel) {
    return (
      stop.plannedArrivalTime &&
      stop.arrivalTime &&
      stop.arrivalTime.getTime() !== stop.plannedArrivalTime.getTime()
    );
  }

  return (
    <div
      data-id={id}
      className={classNames(
        styles.stop,
        isCurrent && styles['stop--current'],
        hasCollapsedStops && styles['stop--collapsed']
      )}
    >
      <div className={styles.stop__visual}>
        {!isLast && (
          <div
            className={classNames(
              styles['stop__visual__path-next'],
              isPrevious &&
                !isAtStop &&
                styles['stop__visual__path-next--approaching'],
              isPassed &&
                !(isPrevious && !isAtStop) &&
                styles['stop__visual__path-next--passed']
            )}
          ></div>
        )}
        <div
          className={classNames(
            styles.stop__visual__location,
            isCurrent && styles['stop__visual__location--current'],
            isPassed && styles['stop__visual__location--passed'],
            stop.isCancelled && styles['stop__visual__location--cancelled'],
            isLast && styles['stop__visual__location--last']
          )}
        ></div>
        {isLast && hasCollapsedStops && !isScrolling && (
          <div
            className={classNames(styles['stop__visual__path-previous'])}
          ></div>
        )}
      </div>
      <div
        ref={stopNameRef}
        className={classNames(
          styles.stop__name,
          stop.isCancelled && styles['stop__name--cancelled'],
          isPassed && styles['stop__name--passed'],
          isCurrent && styles['stop__name--current']
        )}
      >
        {stop.name}
      </div>
      <div className={styles.stop__time}>
        {stop.departureTime && (
          <span className={classNames(styles['departure-time'])}>
            <span className={styles['departure-time__departure']}>Avgår</span>
            <span className={styles['departure-time__time']}>
              {dateToClockFormat(stop.departureTime)}
            </span>
          </span>
        )}

        {stop.isCancelled && !isPassed && (
          <span className={styles['arrival-cancelled']}>Inställd</span>
        )}

        {stop.arrivalTime && (
          <span
            className={classNames(
              styles['arrival-time'],
              !isPassed &&
                isTimeChanged(stop) &&
                styles['arrival-time--changed']
            )}
          >
            {dateToClockFormat(stop.arrivalTime)}
          </span>
        )}

        {stop.plannedArrivalTime && !isPassed && (
          <span
            className={classNames(
              styles['planned-arrival-time'],
              isTimeChanged(stop) && styles['planned-arrival-time--changed'],
              stop.isCancelled && styles['planned-arrival-time--cancelled']
            )}
          >
            {dateToClockFormat(stop.plannedArrivalTime)}
          </span>
        )}
      </div>
    </div>
  );
}
