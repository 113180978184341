import { useEffect, useState } from 'react';
import { formatDepartureTimeCountdown } from '../utils/formatDepartureOrArrivalTime';
import { DepartureOrArrival } from '@shared/models/departureOrArrival';
import { DepartureOrArrivalTimeFormat } from '@shared/models/departureOrArrivalTimeFormat';
import { dateToClockFormat } from '@shared/utils/time';

export function useDepartureDisplayTime(
  departureOrArrival: DepartureOrArrival | undefined,
  format: DepartureOrArrivalTimeFormat
) {
  const [displayTime, setDisplayTime] = useState<number | string | undefined>();

  useEffect(() => {
    function getDisplayTime(
      departureOrArrival: DepartureOrArrival | undefined
    ): number | string | undefined {
      if (!departureOrArrival) {
        return undefined;
      }

      if (departureOrArrival.isCancelled) {
        return 'Inställd';
      }

      if (format === 'time') {
        return dateToClockFormat(
          departureOrArrival.estimatedOtherwisePlannedTime
        );
      }

      return formatDepartureTimeCountdown(
        departureOrArrival.estimatedOtherwisePlannedTime
      );
    }

    setDisplayTime(getDisplayTime(departureOrArrival));

    const intervalId = setInterval(() => {
      setDisplayTime(getDisplayTime(departureOrArrival));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [departureOrArrival, format]);

  return displayTime;
}
