import { useRef } from 'react';
import type { Direction } from '@shared/models/direction';
import styles from './Direction.module.scss';
import { useScaledFontSize } from '@shared/hooks/useScaledFontSize';

interface DirectionProps {
  direction: Direction;
}

export default function Direction({ direction }: DirectionProps) {
  const directionNameRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(directionNameRef);

  const directionViaRef = useRef<HTMLDivElement>(null);
  useScaledFontSize(directionViaRef);
  return (
    <div className={styles['direction-name']}>
      <div ref={directionNameRef} className={styles['direction-name__text']}>
        {direction.name}
      </div>

      {direction.via && (
        <div ref={directionViaRef} className={styles['direction-via']}>
          via {direction.via}
        </div>
      )}
    </div>
  );
}
