import {
  DEPARTURE_PAGING_DEFAULT,
  DEPARTURE_SORTING_DEFAULT,
  DEPARTURE_TIME_FORMAT_DEFAULT,
} from '@shared/constants';
import { StopBoardConfiguration } from '@shared/models/boardConfiguration';
import { DepartureRow } from 'src/modules/departures/models/departureRow';
import DepartureOrArrivalTable from '@shared/components/DepartureOrArrivalTable';
import Message from '@shared/components/Message';
import LoadingPanel from '@shared/components/LoadingPanel';

interface DeparturesModuleProps {
  boardConfiguration: StopBoardConfiguration;
  departureRows?: DepartureRow[];
  departureRowsError?: boolean;
}

export default function DeparturesModule({
  boardConfiguration,
  departureRows,
  departureRowsError,
}: DeparturesModuleProps) {
  if (departureRowsError) {
    return (
      <Message
        title="Just nu kan vi inte visa avgångar på denna skylt"
        text="Sök din resa i appen Västtrafik To Go eller på vasttrafik.se"
      />
    );
  } else if (departureRows === undefined) {
    return <LoadingPanel />;
  } else if (departureRows.length > 0) {
    return (
      <DepartureOrArrivalTable
        departureRows={departureRows}
        sorting={
          boardConfiguration.departureSorting ?? DEPARTURE_SORTING_DEFAULT
        }
        timeFormat={
          boardConfiguration.departureTimeFormat ??
          DEPARTURE_TIME_FORMAT_DEFAULT
        }
        paging={boardConfiguration.departurePaging ?? DEPARTURE_PAGING_DEFAULT}
        showPlatform={
          boardConfiguration.type === 'stop-area' ||
          boardConfiguration.stopPointGids.length > 1
        }
      />
    );
  } else {
    const messageTitle =
      boardConfiguration.departureTimeSpan === 'day'
        ? 'Det går inga avgångar härifrån det närmsta dygnet'
        : 'Det går inga avgångar härifrån den närmsta timmen';
    const messageText =
      boardConfiguration.departureTimeSpan === 'day'
        ? 'Det är minst ett dygn kvar till nästa tur avgår. Sök alternativa resor i appen Västtrafik To Go eller på vasttrafik.se'
        : 'Det är minst en timme kvar till nästa tur avgår. Sök alternativa resor i appen Västtrafik To Go eller på vasttrafik.se';
    return <Message title={messageTitle} text={messageText} />;
  }
}
