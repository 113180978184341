import {
  DEPARTURE_PAGING_DEFAULT,
  DEPARTURE_SORTING_DEFAULT,
  DEPARTURE_TIME_FORMAT_DEFAULT,
  DEPARTURES_TIME_SPAN_DEFAULT,
  MAX_NUMBER_OF_BOARDS,
  SHOW_PLATFORMS_IN_HEADER_DEFAULT,
} from '@shared/constants';
import {
  BoardConfiguration,
  STOP_BOARD_MODULES,
  StopBoardModule,
  type VehicleModule,
} from '@shared/models/boardConfiguration';
import { ConfigurationTheme } from '@shared/models/configurationTheme';
import { DepartureOrArrivalSorting } from '@shared/models/departureOrArrivalSorting';
import { DepartureTimeSpan } from '@shared/models/departureTimeSpan';
import { DepartureOrArrivalTimeFormat } from '@shared/models/departureOrArrivalTimeFormat';
import { Module, MODULES } from '@shared/models/module';
import { isStopArea, isStopPoint } from '@shared/utils/gid';

export function createBoardConfigurations(
  urlParams: URLSearchParams
): BoardConfiguration[] {
  function getModules(boardId: number): Module[] {
    const moduleCandidates = urlParams.getAll(`board${boardId}Modules`);
    const modules = new Array<Module>();
    for (const moduleCandidate of moduleCandidates) {
      if (isModule(moduleCandidate)) {
        modules.push(moduleCandidate);
      } else {
        throw new Error(
          `Invalid module ${moduleCandidate} for board ${boardId}`
        );
      }
    }
    return modules;
  }

  function tryCreateBoardConfiguration(): BoardConfiguration | undefined {
    const modules = getModules(boardId);

    if (modules.includes('departures') && modules.includes('arrivals')) {
      throw new Error(
        `Board ${boardId} cannot have both departures and arrivals modules`
      );
    }

    if (hasOnlyStopBoardModules(modules)) {
      const gids = urlParams.getAll(`board${boardId}Gids`);
      const stopAreaGid =
        gids.length === 1 && isStopArea(gids[0]) ? gids[0] : undefined;

      if (stopAreaGid) {
        return {
          id: boardId,
          type: 'stop-area',
          stopAreaGid,
          modules,
          departureSorting: getDepartureSorting(boardId),
          departurePaging: getDeparturePaging(boardId),
          departureTimeFormat: getDepartureTimeFormat(boardId),
          departureTimeSpan: getDepartureTimeSpan(boardId),
        };
      } else if (gids.length > 0 && gids.every(isStopPoint)) {
        return {
          id: boardId,
          type: 'stop-points',
          stopPointGids: gids,
          modules,
          showPlatformsInHeader: getShowPlatformsInHeader(boardId),
          departureSorting: getDepartureSorting(boardId),
          departurePaging: getDeparturePaging(boardId),
          departureTimeFormat: getDepartureTimeFormat(boardId),
          departureTimeSpan: getDepartureTimeSpan(boardId),
        };
      }
    } else if (isVehicleModule(modules)) {
      return {
        id: boardId,
        type: 'vehicle',
        modules,
      };
    }
  }

  function isModule(moduleCandidate: string): moduleCandidate is Module {
    return MODULES.some((module) => moduleCandidate === module);
  }

  function hasOnlyStopBoardModules(
    modules: Module[]
  ): modules is StopBoardModule[] {
    return modules.every(
      (module) =>
        STOP_BOARD_MODULES.find(
          (moduleCandidate) => moduleCandidate === module
        ) !== undefined
    );
  }

  function isVehicleModule(modules: Module[]): modules is [VehicleModule] {
    return modules.length === 1 && modules[0] === 'vehicle';
  }

  function getShowPlatformsInHeader(boardCounter: number): boolean | undefined {
    const showPlatformsInHeader = urlParams.get(
      `board${boardCounter}ShowPlatformsInHeader`
    );
    return showPlatformsInHeader === 'false' ? false : undefined;
  }

  function getDepartureSorting(
    boardCounter: number
  ): DepartureOrArrivalSorting | undefined {
    const departureSorting = urlParams.get(
      `board${boardCounter}DepartureSorting`
    );
    return departureSorting === 'time' ? departureSorting : undefined;
  }

  function getDeparturePaging(boardCounter: number): boolean | undefined {
    const departurePaging = urlParams.get(
      `board${boardCounter}DeparturePaging`
    );
    return departurePaging === 'false' ? false : undefined;
  }

  function getDepartureTimeFormat(
    boardCounter: number
  ): DepartureOrArrivalTimeFormat | undefined {
    const departureTimeFormat = urlParams.get(
      `board${boardCounter}DepartureTimeFormat`
    );
    return departureTimeFormat === 'time' ? departureTimeFormat : undefined;
  }

  function getDepartureTimeSpan(
    boardCounter: number
  ): DepartureTimeSpan | undefined {
    const departureTimeSpan = urlParams.get(
      `board${boardCounter}DepartureTimeSpan`
    );
    return departureTimeSpan === 'day' ? departureTimeSpan : undefined;
  }

  let boardId = 1;
  let hasBoardAtId = true;
  const newBoards = new Array<BoardConfiguration>();
  while (hasBoardAtId) {
    hasBoardAtId = false;
    const boardConfiguration = tryCreateBoardConfiguration();
    if (boardConfiguration !== undefined) {
      newBoards.push(boardConfiguration);
      hasBoardAtId = true;
      boardId++;
    }
  }

  // Legacy configuration for Avgångstavla
  const stopAreaGids = urlParams.getAll('stopAreaGid');
  for (const stopAreaGid of stopAreaGids) {
    newBoards.push({
      id: boardId++,
      type: 'stop-area',
      stopAreaGid,
      modules: ['departures', 'trafficSituations'],
    });
  }

  if (newBoards.length > MAX_NUMBER_OF_BOARDS) {
    throw new Error(`Max number of boards is ${MAX_NUMBER_OF_BOARDS}`);
  }

  return newBoards;
}

export function createConfigurationQuery(
  boardConfigurations: BoardConfiguration[],
  theme: ConfigurationTheme
): string {
  const urlParams = new URLSearchParams();
  for (const boardConfiguration of boardConfigurations) {
    if (boardConfiguration.type === 'stop-area') {
      addGid(urlParams, boardConfiguration.id, boardConfiguration.stopAreaGid);
    } else if (boardConfiguration.type === 'stop-points') {
      for (const stopPointGid of boardConfiguration.stopPointGids) {
        addGid(urlParams, boardConfiguration.id, stopPointGid);
      }

      if (
        boardConfiguration.showPlatformsInHeader !== undefined &&
        boardConfiguration.showPlatformsInHeader !==
          SHOW_PLATFORMS_IN_HEADER_DEFAULT
      ) {
        urlParams.set(
          `board${boardConfiguration.id}ShowPlatformsInHeader`,
          boardConfiguration.showPlatformsInHeader.toString()
        );
      }
    }

    for (const module of boardConfiguration.modules) {
      urlParams.append(`board${boardConfiguration.id}Modules`, module);
    }

    if (
      boardConfiguration.type === 'stop-area' ||
      boardConfiguration.type === 'stop-points'
    ) {
      if (
        boardConfiguration.departureSorting &&
        boardConfiguration.departureSorting !== DEPARTURE_SORTING_DEFAULT
      ) {
        urlParams.set(
          `board${boardConfiguration.id}DepartureSorting`,
          boardConfiguration.departureSorting
        );
      }

      if (
        boardConfiguration.departurePaging !== undefined &&
        boardConfiguration.departurePaging !== DEPARTURE_PAGING_DEFAULT
      ) {
        urlParams.set(
          `board${boardConfiguration.id}DeparturePaging`,
          boardConfiguration.departurePaging.toString()
        );
      }

      if (
        boardConfiguration.departureTimeFormat !== undefined &&
        boardConfiguration.departureTimeFormat !== DEPARTURE_TIME_FORMAT_DEFAULT
      ) {
        urlParams.set(
          `board${boardConfiguration.id}DepartureTimeFormat`,
          boardConfiguration.departureTimeFormat
        );
      }

      if (
        boardConfiguration.departureTimeSpan &&
        boardConfiguration.departureTimeSpan !== DEPARTURES_TIME_SPAN_DEFAULT
      ) {
        urlParams.set(
          `board${boardConfiguration.id}DepartureTimeSpan`,
          boardConfiguration.departureTimeSpan
        );
      }
    }
  }

  if (theme !== 'dark') {
    urlParams.set(`theme`, theme);
  }

  return urlParams.toString();
}

function addGid(urlParams: URLSearchParams, boardId: number, gid: string) {
  urlParams.append(`board${boardId}Gids`, gid);
}
