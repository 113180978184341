import { SCROLL_TIME_IN_SECONDS } from '@shared/constants';
import { Easing, Group, Tween } from '@tweenjs/tween.js';
import { RefObject, useEffect } from 'react';

export default function useSmoothScroll(
  scrollElementRef: RefObject<HTMLDivElement | null>,
  scrollTop: number,
  onBeginScroll?: () => void,
  onFinishScroll?: () => void
): void {
  useEffect(() => {
    const scrollElement = scrollElementRef.current;
    if (!scrollElement) {
      return;
    }

    onBeginScroll?.();
    const group = new Group();
    const position = { scrollTop: scrollElement.scrollTop };
    const tween = new Tween(position)
      .to({ scrollTop }, SCROLL_TIME_IN_SECONDS * 1000)
      .easing(Easing.Quadratic.Out)
      .onUpdate(() => {
        scrollElement.scrollTop = position.scrollTop;
      })
      .onComplete(() => {
        animating = false;
        onFinishScroll?.();
      })
      .start();
    group.add(tween);

    let animating = true;
    function animate(time: number) {
      if (!animating) {
        return;
      }
      group.update(time);
      requestAnimationFrame(animate);
    }
    requestAnimationFrame(animate);

    return () => {
      animating = false;
    };
  }, [onBeginScroll, onFinishScroll, scrollElementRef, scrollTop]);
}
