import styles from './Message.module.scss';

interface MessageProps {
  title: string;
  text?: string;
}

export default function Message({ title, text }: MessageProps) {
  return (
    <div className={styles['message-container']}>
      <div className={styles.message}>
        <h2 className={styles.message__title}>{title}</h2>
        {text && <p className={styles.message__text}>{text}</p>}
      </div>
    </div>
  );
}
