import { DepartureOrArrivalSorting } from '@shared/models/departureOrArrivalSorting';
import { DepartureTimeSpan } from '@shared/models/departureTimeSpan';
import { DepartureOrArrivalTimeFormat } from '@shared/models/departureOrArrivalTimeFormat';

export type ConfigurationId = number;

export type StopBoardConfiguration =
  | StopAreaBoardConfiguration
  | StopPointsBoardConfiguration;

export type VehicleModule = 'vehicle';

export interface VehicleBoardConfiguration extends BoardConfigurationBase {
  modules: [VehicleModule];
  type: 'vehicle';
}

export type BoardConfiguration =
  | StopBoardConfiguration
  | VehicleBoardConfiguration;

interface BoardConfigurationBase {
  id: ConfigurationId;
}

export const STOP_BOARD_MODULES = [
  'departures',
  'arrivals',
  'trafficSituations',
] as const;
export type StopBoardModule = (typeof STOP_BOARD_MODULES)[number];

interface StopBoardConfigurationBase extends BoardConfigurationBase {
  modules: StopBoardModule[];
  departureSorting?: DepartureOrArrivalSorting;
  departurePaging?: boolean;
  departureTimeFormat?: DepartureOrArrivalTimeFormat;
  departureTimeSpan?: DepartureTimeSpan;
}

export interface IncompleteStopBoardConfiguration
  extends StopBoardConfigurationBase {
  id: -1;
  type: 'stop';
}

export interface StopAreaBoardConfiguration extends StopBoardConfigurationBase {
  type: 'stop-area';
  stopAreaGid: string;
}

export interface StopPointsBoardConfiguration
  extends StopBoardConfigurationBase {
  type: 'stop-points';
  stopPointGids: string[];
  showPlatformsInHeader?: boolean;
}
