import classNames from 'classnames';
import { DepartureOrArrival } from '@shared/models/departureOrArrival';
import ImageIcon, { Icon } from '@shared/components/ImageIcon';
import { useDepartureDisplayTime } from '@shared/hooks/useDepartureOrArrivalDisplayTime';
import styles from './DepartureOrArrivalTableDepartureOrArrivalCell.module.scss';
import { DepartureOrArrivalTimeFormat } from '@shared/models/departureOrArrivalTimeFormat';
import { getHasChangedPlannedClockTime } from '@shared/utils/departureOrArrival';

interface DepartureOrArrivalTableDepartureCellProps {
  departureOrArrival: DepartureOrArrival | undefined;
  compact: boolean;
  format: DepartureOrArrivalTimeFormat;
  hasDepartureOrArrivalWithTrafficSituation: boolean;
  headerWidth?: number;
}

export default function DepartureOrArrivalTableDepartureCell({
  departureOrArrival,
  compact,
  format,
  hasDepartureOrArrivalWithTrafficSituation,
  headerWidth,
}: DepartureOrArrivalTableDepartureCellProps) {
  const departureDisplayTime = useDepartureDisplayTime(
    departureOrArrival,
    format
  );

  const trafficSituationIconType: Icon | undefined =
    departureOrArrival?.trafficSituationSeverity
      ? `traffic-situation-${departureOrArrival.trafficSituationSeverity}`
      : undefined;

  const displayInformation =
    format === 'countdown' ||
    (departureOrArrival && getHasChangedPlannedClockTime(departureOrArrival));

  const renderWheelchairAccessible =
    format === 'countdown' &&
    !departureOrArrival?.isCancelled &&
    departureOrArrival?.isWheelchairAccessible;

  return (
    <td
      className={classNames(
        styles['departure-or-arrival'],
        departureOrArrival?.isCancelled &&
          styles['departure-or-arrival--cancelled']
      )}
      style={{ minWidth: headerWidth }}
    >
      {displayInformation && (
        <div
          className={classNames(
            styles['information'],
            renderWheelchairAccessible &&
              styles['information--wheelchair-accessible'],
            hasDepartureOrArrivalWithTrafficSituation &&
              styles['information--connected-traffic-situation-exist'],
            compact && styles['information--compact']
          )}
        >
          {trafficSituationIconType && (
            <ImageIcon
              type={trafficSituationIconType}
              alt="Avgångstiden har ett störningsmeddelande"
              className={styles['connected-traffic-situation']}
            ></ImageIcon>
          )}
          {departureDisplayTime && (
            <span
              className={classNames(
                styles['information__text'],
                format === 'time' &&
                  !departureOrArrival?.isCancelled &&
                  styles['information__text--has-changed-clock-time']
              )}
            >
              {departureDisplayTime}
            </span>
          )}
          {departureDisplayTime && typeof departureDisplayTime === 'number' && (
            <span className="sr-only">minuter</span>
          )}
          {renderWheelchairAccessible && (
            <ImageIcon
              type="wheelchair-accessible"
              alt="Resan är anpassad för rullstol"
              className={styles['wheelchair-accessible']}
            ></ImageIcon>
          )}
        </div>
      )}
    </td>
  );
}
