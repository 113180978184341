import {
  ARRIVAL_PAGING_DEFAULT,
  ARRIVAL_SORTING_DEFAULT,
  ARRIVAL_TIME_FORMAT_DEFAULT,
} from '@shared/constants';
import { StopBoardConfiguration } from '@shared/models/boardConfiguration';
import Message from '@shared/components/Message';
import LoadingPanel from '@shared/components/LoadingPanel';
import DepartureOrArrivalTable from '@shared/components/DepartureOrArrivalTable';
import { ArrivalRow } from '../models/arrivalRow';

interface ArrivalsModuleProps {
  boardConfiguration: StopBoardConfiguration;
  arrivals?: ArrivalRow[];
  arrivalRowsError?: boolean;
}

export default function DeparturesModule({
  boardConfiguration,
  arrivals,
  arrivalRowsError,
}: ArrivalsModuleProps) {
  if (arrivalRowsError) {
    return (
      <Message title="Just nu kan vi inte visa ankomster på denna skylt" />
    );
  } else if (arrivals === undefined) {
    return <LoadingPanel />;
  } else if (arrivals.length > 0) {
    return (
      <DepartureOrArrivalTable
        arrivalRows={arrivals}
        sorting={ARRIVAL_SORTING_DEFAULT}
        timeFormat={ARRIVAL_TIME_FORMAT_DEFAULT}
        paging={ARRIVAL_PAGING_DEFAULT}
        showPlatform={
          boardConfiguration.type === 'stop-area' ||
          boardConfiguration.stopPointGids.length > 1
        }
      />
    );
  } else {
    return <Message title="Inga ankomster den närmsta timmen" />;
  }
}
