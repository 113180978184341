import classNames from 'classnames';
import { dateToClockFormat } from '@shared/utils/time';
import { DepartureOrArrival } from '@shared/models/departureOrArrival';
import styles from './DepartureOrArrivalTablePlannedTimeCell.module.scss';
import { getHasChangedPlannedClockTime } from '@shared/utils/departureOrArrival';

interface DepartureOrArrivalTablePlannedTimeCellProps {
  departureOrArrival: DepartureOrArrival;
  headerWidth?: number;
}

export default function DepartureOrArrivalTablePlannedTimeCell({
  departureOrArrival,
  headerWidth,
}: DepartureOrArrivalTablePlannedTimeCellProps) {
  const plannedTimeClock = dateToClockFormat(
    departureOrArrival.plannedTime ?? new Date()
  );

  const hasChangedPlannedClockTime =
    getHasChangedPlannedClockTime(departureOrArrival);

  return (
    <td style={{ minWidth: headerWidth }}>
      <span
        className={classNames(
          styles['planned-time'],
          hasChangedPlannedClockTime && styles['planned-time--changed']
        )}
      >
        {plannedTimeClock}
      </span>
    </td>
  );
}
