import { useEffect, useState } from 'react';
import { dateToClockFormat } from '../utils/time';

export default function Clock() {
  const [currentTime, setCurrentTime] = useState(dateToClockFormat(new Date()));

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setCurrentTime(dateToClockFormat(new Date()));
    }, 1000);
    return () => {
      window.clearInterval(intervalId);
    };
  });

  return <>{currentTime}</>;
}
