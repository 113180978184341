import { BoardConfiguration } from '@shared/models/boardConfiguration';
import { BoardState } from '@shared/models/BoardState';
import { useEffect } from 'react';
import { ServiceJourney } from 'src/modules/vehicle/models/serviceJourney';

export function useMqtt(
  boards: BoardConfiguration[] | undefined,
  setBoardStates: React.Dispatch<React.SetStateAction<Map<number, BoardState>>>
) {
  useEffect(() => {
    if (
      boards === undefined ||
      !boards.some((board) => board.type === 'vehicle')
    ) {
      return;
    }

    function createMockServiceJourney(): ServiceJourney {
      return {
        id: '1',
        line: {
          shortName: '1337',
          backgroundColor: '#0000FF',
          foregroundColor: '#FFFF00',
        },
        direction: {
          name: 'The Moon',
        },
        stops: [
          {
            stopPointGid: '0',
            name: 'Hjalmar Brantingsplatsen',
            departureTime: new Date(Date.now() + 1000 * 60 * 1),
          },
          {
            stopPointGid: '1',
            name: 'Tanum Shoppingscenter',
            plannedArrivalTime: new Date(Date.now() + 1000 * 60 * 5),
          },
          {
            stopPointGid: '1',
            name: 'Andra försenade stoppet',
            plannedArrivalTime: new Date(Date.now() + 1000 * 60 * 7),
            arrivalTime: new Date(Date.now() + 1000 * 60 * 8),
          },
          {
            stopPointGid: '2',
            name: 'Tredje inställda stoppet',
            plannedArrivalTime: new Date(Date.now() + 1000 * 60 * 10),
            isCancelled: true,
          },
          {
            stopPointGid: '3',
            name: 'Fjärde stoppet',
            plannedArrivalTime: new Date(Date.now() + 1000 * 60 * 15),
          },
          {
            stopPointGid: '4',
            name: 'Femte stoppet',
            plannedArrivalTime: new Date(Date.now() + 1000 * 60 * 20),
          },
          {
            stopPointGid: '5',
            name: 'Ändhållplats',
            plannedArrivalTime: new Date(Date.now() + 1000 * 60 * 25),
          },
        ],
        currentStopIndex: 0,
        isAtStop: false,
      };
    }

    function moveCurrentStop(): void {
      setBoardStates((previousBoardStates) => {
        const newBoardStates = structuredClone(previousBoardStates);
        newBoardStates.forEach((boardState) => {
          if (boardState.serviceJourney !== undefined) {
            const previousStopIndex =
              boardState.serviceJourney.currentStopIndex;
            const previousStop =
              boardState.serviceJourney.stops[previousStopIndex];
            if (previousStop) {
              previousStop.arrivalTime =
                previousStop.arrivalTime ??
                previousStop.plannedArrivalTime ??
                previousStop.departureTime;
              previousStop.departureTime = undefined;
              previousStop.plannedArrivalTime = undefined;
            }

            boardState.serviceJourney.currentStopIndex =
              (boardState.serviceJourney.currentStopIndex + 1) %
              boardState.serviceJourney.stops.length;

            const currentStop =
              boardState.serviceJourney.stops[
                boardState.serviceJourney.currentStopIndex
              ];
            if (currentStop?.isCancelled) {
              currentStop.arrivalTime = currentStop.plannedArrivalTime;
              boardState.serviceJourney.currentStopIndex =
                (boardState.serviceJourney.currentStopIndex + 1) %
                boardState.serviceJourney.stops.length;
            }

            if (boardState.serviceJourney.currentStopIndex === 0) {
              boardState.serviceJourney = createMockServiceJourney();
            }
          }
        });
        return newBoardStates;
      });
    }

    const moveStopIntervalId = window.setInterval(moveCurrentStop, 2000);

    const vehicleBoardIds = boards
      .filter((board) => board.type === 'vehicle')
      .map((board) => board.id);

    setBoardStates((previousBoardStates) => {
      const newBoardStates = new Map(previousBoardStates);

      vehicleBoardIds.forEach((boardId) => {
        newBoardStates.set(boardId, {
          boardId: boardId,
          configurationId: boardId,
          serviceJourney: createMockServiceJourney(),
        });
      });

      return newBoardStates;
    });

    return () => {
      window.clearInterval(moveStopIntervalId);
      setBoardStates((previousBoardStates) => {
        const newBoardStates = new Map(previousBoardStates);

        vehicleBoardIds.forEach((boardId) => {
          newBoardStates.delete(boardId);
        });

        return newBoardStates;
      });
    };
  }, [boards, setBoardStates]);
}
